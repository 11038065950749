import { render, staticRenderFns } from "./operation_send_and_price_detail.vue?vue&type=template&id=9b05e852&scoped=true"
import script from "./operation_send_and_price_detail.vue?vue&type=script&lang=js"
export * from "./operation_send_and_price_detail.vue?vue&type=script&lang=js"
import style0 from "./operation_send_and_price_detail.vue?vue&type=style&index=0&id=9b05e852&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b05e852",
  null
  
)

export default component.exports